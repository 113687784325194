@media (max-width: 575px){
    .MuiGrid-root.MuiGrid-container.MuiGrid-item.makeStyles-fieldContainer-91.css-lx31tv-MuiGrid-root h6{
        margin: 90px 0 0!important;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-item.makeStyles-fieldContainer-99.css-lx31tv-MuiGrid-root{
        padding-bottom: 60px!important
    }
}

@media (max-width: 425px){
    .MuiGrid-root.MuiGrid-container.MuiGrid-item.makeStyles-fieldContainer-91.css-lx31tv-MuiGrid-root h6{
        margin: 140px 0 0!important;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-item.makeStyles-fieldContainer-99.css-lx31tv-MuiGrid-root{
        padding-bottom: 60px!important
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-item.makeStyles-fieldContainer-99.css-lx31tv-MuiGrid-root h6{
        margin: 40px 0 0!important;
    }
}

@media (max-width: 358px){
    .MuiGrid-root.MuiGrid-container.MuiGrid-item.makeStyles-fieldContainer-99.css-lx31tv-MuiGrid-root h6{
        margin: 60px 0 0!important;
    }
}