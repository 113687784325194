
@media only screen and (max-width: 575px){
   
    .makeStyles-image-64{
        height:90px!important;
    }
    .bottom-part .MuiGrid-root p{
        text-align: center!important;
    }
   
}

@media only screen and (max-width: 400px){
    .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-wrap-xs-nowrap {
        flex-direction: column;
        gap: 12px;
    }
}