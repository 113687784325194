.container {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
  }

  @media only screen and (max-width: 600px){
    .MuiContainer-root h4{
        font-size: 24px;
    }
    .makeStyles-image-28{
        margin-right: 20px!important;
    }
    .makeStyles-faqContainer-35 h3{
        font-weight: 400;
    }
    .MuiGrid-root{
        width: 100%!important;
    }
  }