@media only screen and (max-width: 575px){
    .MuiGrid-root{
        text-align: center;
        word-break: break-word;
    text-align: left;
    }
    .MuiGrid-root img{
        width: 100%!important;
        padding: 0!important;
    }
    .makeStyles-scholarProgramsHeader-102{
        line-height: 32px;
        font-size: 24px;
    }
    .makeStyles-scholarProgramsHeader-102{
        padding-left: 5px;
        padding-right: 5px;
    }
    .makeStyles-overlayText-39 {
        font-size: 28px!important;
    }
    .makeStyles-image-64{
        margin-right: 10px!important;
        width: 150px!important;
    }
    .css-ahj2mt-MuiTypography-root{
        font-size: 28px!important;
        line-height: 36px!important;
    }
    .css-67pqv6-MuiGrid-root{
        padding: 50px 30px!important;
    }
    .makeStyles-footerContainer-63{
        padding: 50px 10px 70px!important;
    }
    .makeStyles-formContainer-36{
        flex-direction: column!important;
        gap: 10px!important;
        padding: 25px 25px 80px!important;
    }
    .css-1k5l4ez-MuiFormControl-root-MuiTextField-root{
        width: calc(100% - 20px)!important;
    }
    .css-ohnh7l .filter-btn{
        /* position: absolute!important;
    top: 54%!important;
    right: 36px!important; */
    }
}

@media only screen and (max-width: 450px){
    .css-ahj2mt-MuiTypography-root {
        font-size: 18px!important;
        line-height: 26px!important;
    }
}