@media only screen and (max-width: 575px){
    .MuiPaper-root.MuiPaper-elevation{
        margin: 0 0 20px;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-5{
        padding: 0 15px;
    }
    .makeStyles-smsWrapper-39{
        margin-left: 0rem!important;
    }
    .css-j204z7-MuiFormControlLabel-root{
        display: flex!important;
        align-items: flex-start!important;
        justify-content: flex-start!important;
    }
}