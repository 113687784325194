/* @media screen and (max-width: 575px) {
   .MuiGrid-root.css-vj1n65-MuiGrid-root {
    margin-top: 0px !important;
   } 
   .makeStyles-headerTextBox-30  {
    height: unset !important;
   }
   /* .height-wrap.MuiGrid-root.MuiGrid-item{
    height:100% !important;
} */
    .MuiGrid-root.MuiGrid-item.makeStyles-headerTextLeftCont-33.css-13i4rnv-MuiGrid-root, .MuiGrid-root.MuiGrid-item.makeStyles-headerTextRightCont-34 {
        margin-left: 0px !important;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-12.MuiGrid-grid-lg-12 {
        padding: 10px 0px!important;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column{
        padding: 10px 15px!important;
    }
    h6.MuiTypography-root.MuiTypography-subtitle1.makeStyles-error-138.css-10wpov9-MuiTypography-root{
        position: absolute!important;
        top: 91%!important;
        margin: 25px 30px!important;
    }
    .makeStyles-detailsContainer-39 {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
        font-size: 14px !important;
        margin-top: 13px !important;
        line-height: 20px !important;
    
    }
    .makeStyles-text-42 img{
        object-fit: contain !important;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column {
        /* margin-bottom: 15px !important; */
        border-radius: 10px !important;
    }
    .makeStyles-text-42 ul{
        padding-left: 10px!important;
    }
    .bottom-part p{
        font-size: 16px!important;
    }
    .techsupport p{
        font-size: 16px!important;
        text-align: center;
    }
    .bottom-part .MuiGrid-root.css-vj1n65-MuiGrid-root p{
        white-space: nowrap!important;
    }
    .makeStyles-headerTextLeftCont-56, .makeStyles-headerTextLeftCont-81{
        padding: 10px!important;
        margin-left: 10px!important;
        margin-right: 10px!important;
    }
    /* .height-wrap{
        min-height: calc(100vh - -900px) !important;
    } */
    .makeStyles-detailsContainer-39{
        padding-top: 70px!important;
    }
    .makeStyles-iconButton-130{
        margin-top: 30px!important;
    }
    .makeStyles-iconButton-88{
        margin-top: 20px!important;
    margin-left: 20px!important;
    }
    .height-wrap {
        height: calc(90vh - -565px) !important;
    }
  
@media (max-width: 575px){
    .MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column{
        padding: 0!important;
    }
}
