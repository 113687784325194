@media only screen and (max-width: 575px) {
    .css-z8fhq1-MuiDataGrid-columnHeaders {
        max-height: 36px !important;
        min-height: 6px !important;
    }

    .css-1hwz2fv-MuiTypography-root {
        font-size: 10px !important;
    }
    .css-1bizp5f-MuiGrid-root {
        justify-content: center !important;
    }

    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        /* min-width: 300px !important; */
        margin:  5px 0 !important;
    }

    .css-1l9sosn-MuiButtonBase-root-MuiButton-root {
        /* min-width: 300px !important; */
        margin-top: 2.5px !important;
    }
    

    .makeStyles-button-75 {
        margin-left: 0px !important;
    }
}