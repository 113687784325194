.required-document-form-buttons {
    margin-left: "auto";
    margin-top:'2rem';
}

@media only screen and (max-width: 575px) { 
    .required-document-form-buttons {
        display: flex;
        flex-direction: column;
        margin-top:'2rem';
    }
}