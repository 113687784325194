.payment-options-first {
    margin-left: 40px;
}

.save-for-later-payment-form {
    margin-left: auto; 
}


@media only screen and (max-width: 575px) { 
    .payment-options-first {
        margin-left: 0px;
    }
    .save-for-later-payment-form {
        display: flex;
        flex-direction: column;
    }
    .makeStyles-button-72 {
        margin-left: 0px !important;
    }
}