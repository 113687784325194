@media only screen and (max-width: 575px){
    .css-1k5l4ez-MuiFormControl-root-MuiTextField-root{
        width: calc(100% - 50px)!important;
    }
    /* .MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column p{
        padding: 0;
    } */
    .css-ohnh7l{
        position: relative;
    }

    .css-nw8zdw-MuiGrid-root{
        display: none !important;
    }
    .css-neb4x5-MuiGrid-root {
        text-align: center;
        padding: 10px 0;
    }
   
    .makeStyles-textWrapper-93{
        padding: 0 !important;
    }
    .makeStyles-detailsContainer-38 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .makeStyles-stageItemComplete-62 {
        display: flex !important;
    }

    .css-1k5l4ez-MuiFormControl-root-MuiTextField-root{
        width: calc(100% - 20px)!important;
    }
    .css-ohnh7l .filter-btn{
        /* position: absolute!important;
        top: 60%!important;
        right: 13px!important; */
        position: absolute;
    right: 0;
    top: 85%;
    }
    .makeStyles-button-97{
        margin: 0 25px!important;
    }
    
}