*{
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 991px){
  .height-wrap {
    height: calc(100vh - -226px) !important;
  }
}
@media (max-width: 600px) {
  .MuiTypography-root.MuiTypography-body1 {
    font-size: 14px;
  }
}
@media (max-width: 767px){
  .info-btns{
    display: flex!important;
    justify-content: end!important;
  }
  .info-btns .MuiGrid-root{
    width: fit-content!important;
  }
  .info-btns .makeStyles-button-13{
    width: 130px;
  }
  .makeStyles-navTextSelected-12, .makeStyles-navText-11{
    font-size: 18px!important;
  }
  .makeStyles-logo-3{
    height: 80px!important;
    width: 80px!important;
  }
  .makeStyles-route-27 {
    margin-left: 18px!important;
  }
}

@media (max-width: 500px){
  .makeStyles-headerImage-31{
    height: 350px!important;
  }
  .image-section {
    height: 350px!important;
  }
}

@media (max-width: 400px){
  .quick-facts .makeStyles-factCardInner-36{
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
  }
}