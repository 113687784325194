/* h6.MuiTypography-root.MuiTypography-subtitle1.makeStyles-error-138.css-10wpov9-MuiTypography-root{
    position: absolute!important;
    top: 91%!important;
    margin: 25px 30px!important;
    background: red!important;
} */
.makeStyles-fieldContainer-90 h6.MuiTypography-root.MuiTypography-subtitle1.makeStyles-error-93.css-10wpov9-MuiTypography-root{
    /* background: blue!important; */
    position: absolute!important;
    /* top: 92% !important; */
    margin: 3px 25px 0 !important;
}
span.MuiTypography-root.MuiTypography-caption.css-1sn4lm3-MuiTypography-root{
    font-size: 17px!important;
    line-height: 24px!important;
    /* height: 80px!important;
    display: block!important; */
}
/* .MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column .MuiGrid-root.MuiGrid-container.MuiGrid-item.makeStyles-fieldContainer-90.css-lx31tv-MuiGrid-root:first-child span{
    font-size: 12px!important;
} */
.MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column .makeStyles-fieldContainer-90:last-child span{
    display: block!important;
    height: 80px!important;
}

@media (max-width: 575px){
    .MuiGrid-root.MuiGrid-container.MuiGrid-item.makeStyles-fieldContainer-91.css-lx31tv-MuiGrid-root{
        padding-bottom: 60px!important;
    }
}
