/* --------responsiveness-start------------- */
.divide{
    margin: 20px 0 0!important;
}
.quick-facts p{
    margin: 10px 0!important;
}
@media screen and (max-width: 1200px) {
    .MuiGrid-container {
        padding-top: 0px !important;
    }
    .makeStyles-headlineText2-38 {
        font-size: 35px !important;
    }
    .makeStyles-text-42 {
        font-size: 14px !important;
        line-height: 25px !important;
    }
    .height-wrap {
        max-height: 100% !important;
    }
}
@media screen and (max-width: 1200px) {
    p.makeStyles-headlineText2-38 {
        font-size: 20px !important;
        font-weight: 600 !important;
        margin-bottom: 5px !important;
    }
}
@media screen and (max-width: 991px) {
    .height-wrap{
        /* height: calc(100vh - -380px) !important; */
    }
    .Quick-facts-wrap .makeStyles-factCard-35 {
        max-width: 100% !important;
        min-height: 100% !important;
    }
    .flex-responsiveness {
        flex-direction: column !important;
        margin: 0 0 15px;
    }
   .makeStyles-detailsContainer-39 {
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
}
@media screen and (max-width: 991px) {
    .Quick-facts-wrap .makeStyles-headerTextRightCont-34  {
        margin-top: 0px !important;
    }
}
@media (max-width: 700px){
   
}
@media screen and (max-width: 899px) {
    .height-wrap{
        /* height: calc(90vh - -630px) !important; */
    }
    .username .makeStyles-fieldWrapper-46{
        width: 50%!important;
    }
}
@media screen and (max-width: 700px) {
    /* .height-wrap{
        height: calc(95vh - -740px) !important;
    } */
}
@media (max-width: 600px){
    .app-div {
        text-align: center;
    }
}
@media screen and (max-width: 575px) {
   .MuiGrid-root.css-vj1n65-MuiGrid-root {
    margin-top: 0px !important;
   } 
   .makeStyles-headerTextBox-30  {
    height: unset !important;
   }
   /* .height-wrap.MuiGrid-root.MuiGrid-item{
    height:100% !important;
} */
    .MuiGrid-root.MuiGrid-item.makeStyles-headerTextLeftCont-33.css-13i4rnv-MuiGrid-root, .MuiGrid-root.MuiGrid-item.makeStyles-headerTextRightCont-34 {
        margin-left: 0px !important;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-12.MuiGrid-grid-lg-12, .MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column {
        padding: 10px 15px!important;
    }
    .makeStyles-detailsContainer-39 {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
        font-size: 14px !important;
        margin-top: 13px !important;
        line-height: 20px !important;
    
    }
    .makeStyles-text-42 img{
        object-fit: contain !important;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-direction-xs-column {
        /* margin-bottom: 15px !important; */
        border-radius: 10px !important;
    }
    .makeStyles-text-42 ul{
        padding-left: 10px!important;
    }
    .bottom-part p{
        font-size: 16px!important;
    }
    .techsupport p{
        font-size: 16px!important;
        text-align: center;
    }
    .bottom-part .MuiGrid-root.css-vj1n65-MuiGrid-root p{
        white-space: nowrap!important;
    }
    .makeStyles-headerTextLeftCont-56, .makeStyles-headerTextLeftCont-81{
        padding: 10px!important;
        margin-left: 10px!important;
        margin-right: 10px!important;
    }
    /* .height-wrap{
        min-height: calc(100vh - -900px) !important;
    } */
    .makeStyles-detailsContainer-39{
        padding-top: 70px!important;
    }
    .makeStyles-iconButton-130{
        margin-top: 30px!important;
    }
    .makeStyles-iconButton-88{
        margin-top: 20px!important;
    margin-left: 20px!important;
    }
    .height-wrap {
        height: calc(90vh - -565px) !important;
    }
}

@media (max-width: 450px){
    .bottom-section{
       flex-direction: column!important;
        width: 100%!important;
    }
    .bottom-section img{
        padding: 15px 0!important;
        margin-right: 0!important;
        width: 300px!important;
        height: 150px!important;
    }
    .techsupport p{
        margin-left: 0!important;
    }
}
@media (max-width: 400px){
    .application-dropdown{
        padding: 1.5rem 0rem!important;
    }
    .application-dropdown .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
        padding-right: 5px!important;
        padding-left: 15px!important;
    }
}
/* --------responsiveness-end------------- */